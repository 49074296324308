import React, { useState } from "react";
import styled from "styled-components";
import ButtonSalta from "./ButtonSalta";
import ModalResultsSalta from "./ModalResultsSalta";
import ModalCheckTicketSalta from "./ModalCheckTicketSalta";

const commonExtraBtnStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  borderRadius: "8px",
  fontSize: ".9rem",
  padding: "8px 20px",
};

const ExtraButtonsHomeSalta = (props) => {
  const [openResults, setOpenResults] = useState(false);
  const [openCheckTicket, setOpenCheckTicket] = useState(false);

  return (
    <DivExtraButtons>
      <ButtonSalta
        variant="primary"
        onClick={() => setOpenCheckTicket(true)}
        style={commonExtraBtnStyles}
      >
        <span
          className="iconify"
          data-icon="healthicons:award-trophy"
          data-inline="false"
          data-width="20px"
        ></span>
        ¿Mi cupón tiene premio?
      </ButtonSalta>
      <ButtonSalta
        variant="primary"
        onClick={() => setOpenResults(true)}
        style={commonExtraBtnStyles}
      >
        <span
          className="iconify"
          data-icon="mingcute:plus-fill"
          data-inline="false"
          data-width="20px"
        ></span>
        ¿Que números salieron?
      </ButtonSalta>

      <ModalResultsSalta
        open={openResults}
        handleClose={() => setOpenResults(false)}
      />
      
      <ModalCheckTicketSalta
        open={openCheckTicket}
        handleClose={() => setOpenCheckTicket(false)}
        size={props.size}
      />
    </DivExtraButtons>
  );
};

export default ExtraButtonsHomeSalta;

const DivExtraButtons = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
`;
