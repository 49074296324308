import React from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import $ from "jquery";
import { checkOrg } from "./utils/generalUtils";

const CarrouselPublicidad = ({
  viewTrivia,
  setviewTrivia,
  documento,
  token,
}) => {
  const alertaYaJugo = () => {
    $.confirm({
      title: '<b className="op-text">Trivia</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
          Ya participó en TRIVIA MUNDIAL
					 </h5>`,
      buttons: {
        Si: {
          text: "Aceptar",
          action: function () {},
        },
      },
    });
  };

  const handleClick = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_PARTICIPANDO_TRIVIA +
      "=" +
      documento;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data === true) {
          alertaYaJugo();
        } else {
          setviewTrivia(false);
        }
      });
  };

  const isDiffOrg = checkOrg(["23", "53"]);

  return (
    <Carousel
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={4200}
      transitionTime={1000}
    >
      {/* <div
        style={{
          margin: isDiffOrg ? ".5em 0" : "2em 0",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION) && (
          <Link
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              height: "100%",
              width: "100%",
            }}
            to={"/identificadorQRCliente/0"}
          ></Link>
        )}
        <img
          src={
            require("./img/PUBLI_1_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
        />
      </div> */}

      <div
        style={{
          margin: isDiffOrg ? ".5em 0" : "2em 0",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION) && (
          <a
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              height: "100%",
              width: "100%",
            }}
            href={"https://www.youtube.com/c/Loter%C3%ADadeSaltaok/streams"}
            target="_blank"
          ></a>
        )}
        <img
          src={
            require("./img/PUBLI_2_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
        />
      </div>

      {process.env.REACT_APP_COD_ORGANIZACION == "12" ||
      process.env.REACT_APP_COD_ORGANIZACION == "23" ||
      process.env.REACT_APP_COD_ORGANIZACION == "42" ||
      process.env.REACT_APP_COD_ORGANIZACION == "53" ? (
        <div
          style={{
            margin: isDiffOrg ? ".5em 0" : "2em 0",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION) && (
            <Link
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10,
                height: "100%",
                width: "100%",
              }}
              to={"/identificadorQRCliente/1"}
            ></Link>
          )}
          <img
            src={
              require("./img/PUBLI_3_ORG_" +
                process.env.REACT_APP_COD_ORGANIZACION +
                ".png").default
            }
          />
        </div>
      ) : (
        <></>
      )}
    </Carousel>
  );
};

export default withRouter(CarrouselPublicidad);
