import React from "react";

const mediosPago = () => {
  return (
    <div className="p-1 mx-auto">
      <p className="text-small text-justify">
        Luego de registrarte y activar tu cuenta, vas a tener una billetera
        virtual con la que podrás hacer tus apuestas, acreditar tus premios, y
        realizar depósitos y retiros de manera sencilla.
      </p>

      <h5 className="text-center mb-3 mt-3">DEPÓSITOS</h5>

      <p className="text-small text-justify">
        Podés ingresar dinero desde cualquier lugar leyendo el QR con tu
        billetera virtual. Una vez que tengas saldo en tu billetera, podés
        divertirte accediendo a tus juegos preferidos.
      </p>

      <h5 className="text-center mb-3 mt-3">RETIROS</h5>

      <p className="text-small text-justify">
        Ingresá a la opción "Retiros" e indicá el monto a retirar. Ahora tenés
        dos opciones para retirar tu dinero:
        <br />
      </p>
      <ol>
        <li>
          <p className="text-small text-justify">
            <span style={{ fontWeight: "bolder", fontFamily: "Mont-Bold" }}>
              Retiro en punto de venta:{" "}
            </span>{" "}
            En opción Retirar Dinero, podés elegir retirar a través de un Punto
            de Venta. Una vez elegido el monto de retiro, se generará una
            solicitud y un código QR que deberás presentarlo al vendedor. El
            vendedor realizará el retiro mediante el dispositivo de juego,
            debitando el monto de tu cuenta en forma inmediata. Te entregará el
            efectivo y un comprobante por la operación realizada.{" "}
          </p>
        </li>
        <li>
          <p className="text-small text-justify">
            <span style={{ fontWeight: "bolder", fontFamily: "Mont-Bold" }}>
              Transferencia bancaria:{" "}
            </span>{" "}
            Podés asociar un CBU o CVU a tu cuenta para recibir el dinero
            directamente en tu banco sin moverte. Los retiros solicitados antes
            de las 12pm se acreditarán el mismo día. Antes de retirar el dinero
            te pedirá los 22 dígitos de tu CBU/CVU, que debe ser del mismo
            titular.{" "}
          </p>
        </li>
      </ol>
      <p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />.
      </p>
    </div>
  );
};

export default mediosPago;
