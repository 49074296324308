import React from "react";

const preguntasFrecuentes = () => {
  return (
    <div className=" mx-auto" id="accordionQA">
      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA1"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA1"
              aria-expanded="false"
              aria-controls="collapseQA1"
            >
              Sobre la Registración
            </div>
          </h5>
        </div>

        <div
          id="collapseQA1"
          className="collapse show"
          aria-labelledby="QA1"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">¿Cómo me registro?</legend>
            <p className="text-small text-justify">
              Podés registrarte desde cualquier dispositivo tocando la opción
              "Registrarse" en la pantalla de inicio.
            </p>

            <legend className="text-center mb-3">
              ¿Por qué tengo que registrarme?
            </legend>
            <p className="text-small text-justify">
              Al registrarte, vas a poder jugar a todos los productos que ofrece
              Lotería de Salta Online. Con tu registro, abrís una cuenta donde
              vas a administrar tus datos personales y tus apuestas a través de
              una billetera virtual. Podrás hacer tus apuestas cuando hayas
              hecho un depósito en tu cuenta.
            </p>

            <legend className="text-center mb-3">
              ¿Por qué dar mis datos?
            </legend>
            <p className="text-small text-justify">
              Es necesario que tus datos sean correctos para poder usar los
              productos. Tenés que ser mayor de 18 años, residente de Salta y tu
              correo se usa para mantener tu cuenta activa en caso de olvido de
              contraseña o cualquier necesidad de comunicarnos.
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA2"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA2"
              aria-expanded="false"
              aria-controls="collapseQA2"
            >
              Sobre tu Cuenta
            </div>
          </h5>
        </div>

        <div
          id="collapseQA2"
          className="collapse"
          aria-labelledby="QA2"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo administro mi cuenta?
            </legend>
            <div className="text-small text-justify mb-3">
              Administrar tu cuenta es muy fácil.
              <br></br>
              Podés ver tus datos a través de la opción "Mi cuenta" o "Usuario"
              y hacer las siguientes funciones:
              <br></br>
              <br></br>
              <ul>
                <li>
                  Activar o desactivar las notificaciones de premios enviados
                  por correo electrónico.
                </li>
                <li>
                  Cambiar tu clave de acceso, seleccionando la opción “Modificar
                  contraseña”.
                </li>
              </ul>
              Para consultar tu saldo y movimientos de tu cuenta, seleccioná la
              opción "Billetera".
            </div>

            <legend className="text-center mb-3">Olvidé mi contraseña</legend>
            <p className="text-small text-justify">
              Podés generar una nueva contraseña al ingresar a la aplicación y
              seleccionar la opción “¿Olvidaste tu contraseña?”. Se te enviará
              un correo con un enlace para que puedas generar tu nueva clave.
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA3"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA3"
              aria-expanded="false"
              aria-controls="collapseQA3"
            >
              Depósitos y Retiros
            </div>
          </h5>
        </div>

        <div
          id="collapseQA3"
          className="collapse"
          aria-labelledby="QA3"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo realizo depósitos en mi cuenta?
            </legend>
            <p className="text-small text-justify">
              Podés ingresar dinero leyendo el QR con tu billetera virtual o
              banco o acercándote a cualquier Punto de Venta de Lotería de
              Salta.
            </p>

            <legend className="text-center mb-3">
              ¿Cómo realizo retiros de mi cuenta?
            </legend>
            <p className="text-small text-justify">
              <span style={{ fontWeight: "bolder", fontFamily: "Mont-Bold" }}>
                Retiro en punto de venta:{" "}
              </span>{" "}
              En opción Retirar Dinero, podés elegir retirar a través de un
              Punto de Venta. Una vez elegido el monto de retiro, se generará
              una solicitud y un código QR que deberás presentarlo al vendedor.
              El vendedor realizará el retiro mediante el dispositivo de juego,
              debitando el monto de tu cuenta en forma inmediata. Te entregará
              el efectivo y un comprobante por la operación realizada.
              <br />
              <br />
              <span style={{ fontWeight: "bolder", fontFamily: "Mont-Bold" }}>
                Transferencia bancaria:{" "}
              </span>{" "}
              Podés asociar un CBU o CVU a tu cuenta para recibir el dinero
              directamente en tu banco sin moverte. Los retiros solicitados
              antes de las 12pm se acreditarán el mismo día. Antes de retirar el
              dinero te pedirá los 22 dígitos de tu CBU/CVU, que debe ser del
              mismo titular.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default preguntasFrecuentes;
